import { Box, useTheme } from '@mui/material';
import { type FC } from 'react';

interface IErrorMessage {
  errorMessage: string;
}

export const ErrorMessage: FC<IErrorMessage> = ({ errorMessage }) => {
  const theme = useTheme();
  return errorMessage ? (
    <Box
      sx={{
        fontSize: '0.875em',
        margin: '0.25rem 0.875rem 0',
        color: theme.palette.error.main,
      }}
    >
      {errorMessage}
    </Box>
  ) : null;
};
